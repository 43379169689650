import React from "react";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import ProductPage from "../components/ProductPage/ProductPage";
import { chickenProducts } from "../data/chicken/chickenProduct";
import { turkeyRecipes } from "../data/turkeyRecipes";
import RecipeGrid from "../components/RecipeGrid/RecipeGrid";
import InspirationGrid from "../components/InspirationGrid/InspirationGrid";
import { hamRecipes } from "../data/hamRecipes";
import { chickenRecipes } from "../data/chickenRecipes";
import _ from "lodash";

const chicken = (props) => {
  const dataArray = [...hamRecipes, ...turkeyRecipes, ...chickenRecipes];
  const filterData = _.filter(dataArray, (obj) => {
    return _.includes(obj.filterProtein, "chicken");
  });
  const chickenImg = getImage(props.data.allFile.edges[0].node);
  return (
    <Layout title="Chicken" description="">
      <main>
        <ProductPage
          image={chickenImg}
          proteinProduct={chickenProducts}
          ProductName="chicken"
        />

        <div>
          <RecipeGrid
            filteredCards={filterData}
            text={"DELICIOUS CHICKEN RECIPES"}
          />
        </div>

        <div>
          <InspirationGrid
            filterSelected="chicken"
            text={"CHICKEN INSPIRATIONS"}
          />
        </div>
      </main>
    </Layout>
  );
};

export const query = graphql`
  query {
    allFile(
      filter: { relativePath: { in: "chicken/SECTION-HEADER-CHICKEN-1.jpg" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(
              blurredOptions: { toFormat: WEBP }
              formats: WEBP
              layout: FULL_WIDTH
              placeholder: BLURRED
              quality: 100
              webpOptions: { quality: 100 }
            )
          }
        }
      }
    }
  }
`;

export default chicken;
